@layer components {
  .select {
    @apply relative;
  }

  .select select {
    @apply block py-2 pl-3.5 pr-7 w-full max-w-full;
    @apply text-dark-grey;
    @apply border border-grey-600 rounded;
    @apply appearance-none cursor-pointer bg-white outline-none;
    @apply hover:border-dark-grey-400;
    @apply focus:border-blue;
    @apply disabled:border-dark-grey-100 disabled:bg-grey disabled:cursor-not-allowed;
  }

  .select.error select {
    @apply border-red hover:border-red focus:border-red;
  }

  .select::after {
    font-family: 'GoSky';
    content: '\e907';
    @apply absolute right-3 top-1/2 transform -translate-y-1/2;
    @apply text-dark-grey-700 pointer-events-none;
  }

  .m-select__control {
    @apply block py-1.25 px-2.5 w-full max-w-full;
    @apply text-md text-dark-grey;
    @apply border rounded;
    @apply cursor-pointer bg-white outline-none;
    @apply disabled:border-dark-grey-100 disabled:bg-grey disabled:cursor-not-allowed;
    @apply border-dark-grey-100 focus-within:border-blue hover:border-dark-grey-400 !important;
  }

  .m-select__control .m-select__value-container {
    @apply p-0;
  }

  .m-select__value-container .m-select__multi-value {
    @apply m-0 mr-1 px-2 py-1 rounded-2xl;
    @apply bg-yellow-600 bg-opacity-15;
  }

  .m-select__value-container .m-select__placeholder {
    @apply text-dark-grey opacity-30;
  }

  .m-select__control .m-select__indicators {
    @apply hidden;
  }

  .m-select__control.m-select__control--is-focused {
    @apply shadow-none;
  }

  .m-select__control .m-select__multi-value__label {
    @apply p-0 text-13 leading-4.5 text-yellow-600;
  }

  .m-select__menu .m-select__menu-list {
    @apply p-0;
    @apply text-15;
  }

  .m-select__menu .m-select__option {
    @apply px-3 py-1;
  }

  .m-select__menu .m-select__option--is-focused {
    @apply bg-grey-600;
  }

  .m-select__menu .m-select__multi-value {
    @apply text-13;
  }

  .label-shape .m-select__multi-value {
    @apply rounded-full p-0.5;
  }

  .m-select__multi-value .m-select__multi-value__remove {
    @apply hover:bg-transparent hover:text-current;
    @apply pr-0 pl-2;
  }

  .m-single-tag .m-select__single-value {
    @apply m-0 px-2 py-1 rounded-2xl;
    @apply bg-yellow-600 bg-opacity-15;
    @apply text-13 leading-4.5 text-yellow-600;
  }
}
